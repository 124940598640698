<template>
  <div>
    <validation-observer
        ref="refFormObserver"
    >
      <!--      Ticket Start-->
      <h4>{{$t('message.Avia Tickets')}}</h4>
      <validation-provider
          #default="validationContext"
          :name="$t('message.Avia Tickets')"
          rules="required" class="w-100">
        <label class="mt-2">{{$t('message.Avia Tickets')}}</label>
        <b-form-select
            v-model="tours.tour.avia_ticket_id"
            :options="avia_tickets"
            text-field="name"
            value-field="id"
            @input="getTicketSelect"
            style="height: 40px"
            class="w-100"
        />
        <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
      </validation-provider>
      <b-row class="mt-2">
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Gross_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="actual_price">{{$t('message.Gross_amount')}}</label>
            <b-input v-model="tours.avia_sales.actual_price"></b-input>
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider #default="validationContext" :name="$t('message.Net_amount')" rules="required|numeric" class="w-100 mt-1">
            <label for="market_price">{{$t('message.Net_amount')}}</label>
            <b-input v-model="tours.avia_sales.market_price"></b-input>
            <span class="text-danger">
            {{ validationContext.errors[0] }}
          </span>
          </validation-provider>
        </b-col>
      </b-row>
      <!--      Ticket End-->

      <div class="w-100 d-flex justify-content-end mt-3">
        <b-button variant="primary" @click="handleSubmit">
          {{$t('message.Save')}}
        </b-button>
        <b-button variant="warning" class="ml-1" @click="$emit('closeModal')">
          {{$t('message.Cancel')}}
        </b-button>
      </div>
      <b-modal v-model="hotelModal" ok-only hide-footer size="lg" no-close-on-backdrop>
        <TourHotelsForm @closeTourHotels="closeTourHotels" @passHotels="passHotels" />
      </b-modal>

    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, numeric } from '@validations'
import {addTours, editTours, getToursById} from "@/api/tours";
import {createTicketsById} from "@/api/avia-tickets";
import TourHotelsForm from '@/views/directories/clients/includes/TourHotelsForm'
import { getPartners } from '@/api/partners'
import { getCountries } from '@/api/countries/countries'
import vSelect from 'vue-select';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/ru.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

export default {
  name: 'TicketsSalesForm',
  components: {
    TourHotelsForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props:{
    new_ticket:{
      default: null
    },
    id:{
      default:null
    }
  },
  data() {
    return {
      required,
      numeric,
      tours: {
        tour: {
          id: null,
          client_id: null,
          name: '',
          start_date: null,
          end_date: null,
          avia_ticket_id:'',
          partner_id: null,
          actual_price: null,
          market_price: null,
        },
        avia_sales: {
          actual_price: null,
          market_price: null,
        },
        visa: {
          title: '',
          actual_price: null,
          market_price: null,
          country_id: null,
        },
        insurance: {
          start_date:null,
          end_date: null,
          actual_price: null,
          market_price: null,
        },
        hotel: {
          id: null,
          name: '',
          actual_price: null,
          market_price: null,
        },
        hotels: [],
      },
      avia_tickets:[],
      formModalActive: false,
      hotelModal: false,
      partners: [],
      visaCountry: null,
      countries: [],
      countryTimeOut: null,
    }
  },
  mounted() {
    createTicketsById().then(res => {
      this.avia_tickets = res.data.data
      this.avia_tickets.unshift({name:'add_ticket', id:0})
    })
    this.fetchPartners();
  },
  methods: {
    closeTourHotels() {
      this.hotelModal = false;
    },
    fetchTours() {
      getToursById(this.id).then(res => {
        this.tours = res.data.data;
      })
    },
    fetchPartners() {
      getPartners({pageSize: 50}).then(res => {
        this.partners = res.data.data.data;
      })
    },
    getTicketSelect(){
      if(this.tours.tour.avia_ticket_id === 0){
        this.$emit("openTicketModal")
      }
    },
    closeModal(){
      this.formModalActive = false
    },
    passHotels(hotels, clientHotel) {
      this.tours.hotels = hotels.map(item => item.id);
      this.tours.hotel.name = clientHotel.name;
      this.tours.hotel.id = clientHotel.id;
      this.hotelModal = false;
    },
    handleSubmit() {
      const isValid = this.$refs.refFormObserver.validate()
      if(isValid) {
        this.tours.tour.client_id = this.$route.params.id;
        this.tours.visa.country_id = this.visaCountry.id;
        addTours(this.tours).then(res => {
          this.$emit('closeModal');
        })
      }
    },
    SearchVisaCountry(search) {
      this.countryTimeOut = null;
      this.countryTimeOut = setTimeout(() => {
        getCountries({search}).then(res => {
          this.countries = res.data.data.data;
        })
      },500);
    }
  },
  watch: {
    id:{
      immediate: true,
      handler(val) {
        if(val) {
          this.fetchTours()
        }
      }
    },
    new_ticket:{
      immediate: true,
      handler(val){
        if(val){
          this.avia_tickets.push(val)
          this.tours.tour.avia_ticket_id = val.id
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    {{$t('message.Add_hotel')}}
    <b-row>
      <b-col cols="10">
        <div class="w-100">
          <v-select v-model="selectedHotels" multiple :options="hotels" label="name" @search="searchHotel" class="w-100"
            @option:deselecting="deselectHotel">
          </v-select>
        </div>
        <div class="w-100 mt-3">
          <b-list-group>
            <b-list-group-item v-for="(hotel, index) in selectedHotels" :key="index">
              <b-form-radio-group v-model="clientHotelChoice" name="radio-options">
                <b-form-radio :value="hotel.id">{{hotel.name}}</b-form-radio>
              </b-form-radio-group>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
      <b-col cols="2">
        <b-button variant="primary" size="sm" @click="addNewHotelModal=true">
          <b-icon icon="plus"></b-icon>{{$t('message.Add')}}
        </b-button>
      </b-col>
    </b-row>

    <div class="w-100 mt-3 d-flex justify-content-end">
      <b-button variant="warning" @click="$emit('closeTourHotels')" class="mr-1">{{$t('message.back')}}</b-button>
      <b-button variant="primary" @click="passHotels" :disabled="!clientHotelChoice || selectedHotels.length === 0">Add
      </b-button>
    </div>

    <b-modal ok-only size="lg" v-model="addNewHotelModal" hide-footer no-close-on-backdrop>
      <HotelsForm :id="id" :countries="countries" @closeModal="closeModal" :disabled="disabled" />
    </b-modal>
  </div>
</template>

<script>
  import HotelsForm from '@/views/directories/hotels/components/HotelsForm'
  import {
    getCountries
  } from '@/api/countries/countries'
  import vSelect from 'vue-select'
  import {
    getHotels
  } from '@/api/hotels'
  export default {
    name: 'TourHotelsForm',
    components: {
      HotelsForm,
      vSelect
    },
    data() {
      return {
        addNewHotelModal: false,
        countries: [],
        id: null,
        hotels: [],
        selectedHotels: [],
        clientHotelChoice: null,
        disabled: false,
      }
    },
    mounted() {
      this.fetchCountries()
    },
    methods: {
      fetchCountries() {
        getCountries().then(res => {
          this.countries = res.data.data.data;
        })
      },
      closeModal(data = null) {
        this.disabled = true;
        setTimeout(() => {
          this.disabled = false;
        }, 1000)
        this.addNewHotelModal = false;
        if (data) {
          this.hotels.push(data);
          this.selectedHotels.push(data);
        }
      },
      searchHotel(search) {
        this.searchTimeOut = setTimeout(() => {
          getHotels({
            search
          }).then(res => {
            this.hotels = res.data.data;
          })
        }, 400)
      },
      deselectHotel(data) {
        if (this.clientHotelChoice) {
          if (data.id === this.clientHotelChoice) {
            this.clientHotelChoice = null;
          }
        }
      },
      passHotels() {
        this.$emit('passHotels', this.selectedHotels, this.selectedHotels.filter(item => item.id === this
          .clientHotelChoice)[0])
      }
    }
  }
</script>

<style scoped>

</style>
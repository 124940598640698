<template>
  <div>
    <b-card>
      <b-row class="align-items-end">
        <b-col md="3"
          v-if="checkRoleUser"
        >
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.Branches')}}
            </p>
            <div class="wrapper-form">
              <v-select
                class="w-100"
                label="name"
                v-model="filter.branch_id"
                :options="branchesOptions"
                @input="getDataByFilter"
              ></v-select>
            </div>
          </div>
        </b-col>

        <b-col md="3">
          <div class="wrapper">
            <p class="wrapper-title">
              {{$t('message.tableHeader.date')}}
            </p>
            <div class="wrapper-form">
              <input type="date" class="form-control" v-model="filter.date" @input="getDataByFilter">
            </div>
          </div>
        </b-col>

        <b-col md="3" class="ml-auto my-1">
          <div class="d-flex justify-content-end w-100">
            <b-button
              variant="primary"
              @click="getDataByFilter"
            >
              {{$t('message.seachLabel')}}
            </b-button>
            <b-button variant="warning" class="ml-1" @click="reportModal=true">
              {{$t('Report')}}
            </b-button>

            <b-button
              class="ml-2"
              variant="primary"
              @click="goToRouter"
            >
              {{$t('message.Add')}}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-table :fields="fields" :items="ticket" responsive :busy="isBusy">
        <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
        <template #cell(id)="data">
          <span class="text-md text-primary"> {{ ((parseInt(pagination.page) - 1) * parseInt(pagination.pageSize)) + (parseInt(data.index) + 1) }}</span>
        </template>
        <template #cell(hotel)="{item}">
          <div class="d-flex">
            <box-icon type='solid' name='buildings'></box-icon>
            <span>
              {{ item.sale.hotel.name }}
            </span>
          </div>
        </template>
        <template #cell(client)="{item}">
          <div class="mb-1">{{item.client.full_name}}</div>
          <div class="w-100 text-info" v-for="(contact,index) in JSON.parse(item.client.contacts)" :key="index">
            {{contact.number}}
          </div>
        </template>
        <template #cell(ticket)="{item}">
          {{item.sale.ticket.name}} <br>
          <span class="text-primary">
            <box-icon type="solid" name="plane-take-off" size="sm"></box-icon>
            <span class="font-small-3">
              {{item.sale.ticket.fly_datetime | onlyDate}} {{item.sale.ticket.fly_datetime | onlyTime}}
            </span>
          </span>
          <br>
          <span class="text-warning">
            <span class="d-flex">
              <box-icon name='plane-land' type='solid'></box-icon>
              <span class="font-small-3">
                {{item.sale.ticket.arrive_datetime | onlyDate}} {{item.sale.ticket.arrive_datetime | onlyTime}}
              </span>
            </span>
          </span>
        </template>
        <template #cell(visa)="{item}">
          <div class="d-flex">
            <box-icon name='visa' type='logo'></box-icon>
            <span>
              {{item.sale.visa.title}}
            </span>
          </div>
          <p class="d-flex mt-1">
            <box-icon name='building'></box-icon> {{item.sale.visa.country.name_uz}}
          </p>
        </template>
        <template #cell(insurance)="{item}">
          <div class="d-flex">
            <box-icon type='solid' name='calendar-plus'></box-icon>
            <span>
              {{item.sale.insurance.start_date | onlyDate}}
            </span>
          </div>
          <div class="d-flex">
            <box-icon type='solid' name='calendar-minus'></box-icon>
            <span>
              {{item.sale.tour_id | onlyDate}}
            </span>
          </div>
        </template>
        <template #cell(fly_datetime)="{item}">
          {{item.fly_datetime | dateFormat}}
        </template>
        <template #cell(arrive_datetime)="{item}">
          {{item.arrive_datetime | dateFormat}}
        </template>
        <template #cell(action)="data">
          <div class="d-flex">
            <div>
              <b-button
                variant="outline-success"
                size="sm"
                @click="handleShow(data.item.id)"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-warning" size="sm" @click="handleEdit(data.item.id)">
                <b-icon icon="pencil"></b-icon>
              </b-button>
            </div>
            <div class="ml-1">
              <b-button variant="outline-danger" size="sm" @click="handleDelete(data.item.id)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-pagination v-model="pagination.page" :total-rows="total" :per-page="pagination.pageSize" @input="fetchTickets">
    </b-pagination>

    <b-modal hide-footer size="lg" v-model="formModalActive" no-close-on-backdrop scrollable ok-only>
      <FormTickets :id="id" @closeModal="closeTicketModal" />
    </b-modal>
    <b-modal v-model="reportModal" hide-footer>
      <b-overlay :show="showLoading">
        <div class="w-100">
          <b-form-input v-model="report.name"></b-form-input>
        </div>
        <div class="w-100 mt-2">
          <input type="date" class="form-control" v-model="report.start_date">
        </div>
        <div class="w-100 mt-2">
          <input type="date" class="form-control" v-model="report.end_date">
        </div>

        <div class="w-100 mt-3 d-flex justify-content-end">
          <b-button variant="success" @click="generateExcel">{{$t('GenerateReport')}}</b-button>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
  import {getBranches} from '@/api/branchs/branchs'
  import Swal from 'sweetalert2'
  import {
    deleteTours,
  } from "@/api/tours";
  import moment from 'moment';
  import TicketsSalesForm from "@/views/avia-tickets-sales/components/TicketSalesForm";
  import FormTickets from "@/views/avia-tickets-sales/components/FormTickets";
  import {
    getTickets, deleteTickets
  } from "@/api/avia-tickets";
  import {mapGetters} from 'vuex'
  import { getTicketExcel } from '@/api/reports/avia-ticket'

  export default {
    name: 'Index',
    components: {
      FormTickets,
      TicketsSalesForm,
    },
    filters: {
      onlyDate(val) {
        return moment(val).format('D-MM-Y');
      },
      onlyTime(val) {
        return moment(val).format('HH:mm');
      }
    },
    data() {
      return {
        new_ticket: null,
        id: null,
        avia_tickets: [],
        reportModal: false,
        fields: [{
            key: 'id',
            label: 'ID'
          },
          {
            key:'client',
            label: this.$t('message.Client')
          },
          {
            key: 'fly_datetime',
            label: this.$t('message.Fly_datetime')
          },
          {
            key: 'arrive_datetime',
            label: this.$t('message.Arrive_datetime')
          },
          {
            key: 'ticket_number',
            label: this.$t('data.ticket_number')
          },
          {
            key: 'order_number',
            label: this.$t('data.booking_number')
          },
          {
            key: 'avia_company.name',
            label: this.$t('message.AviaCompanies')
          },
          {
            key: 'action',
            label: this.$t('message.Action')
          },
        ],
        ticket: [],
        formModalActive: false,
        modalActive: false,
        existingTourModal: false,
        pagination: {
          page: 1,
          pageSize: 20,
        },
        total: 1,
        filter: {
          branch_id: null,
          date: null,
        },
        newFilter: {
          branch_id: null,
          date: null
        },
        report:{
          name: '',
          start_date: null,
          end_date: null,
        },
        showLoading: false,
        branchesOptions: [],
        isBusy: true,
      }
    },
    computed: {
      ...mapGetters('auth', ['activeUser']),
      checkRoleUser() {
        if(this.activeUser !== null) {
          return this.activeUser.roles.some(item => item.name == 'SuperAdmin')
        }
        return false
      }
    },
    methods: {
      fetchTickets() {
        getTickets({...this.pagination,...this.newFilter}).then(response => {
          this.ticket = response.data.data
          this.total = response.data.total
          this.isBusy = false;
        })
      },
      handleAdd() {
        this.id = null;
        this.formModalActive = true
      },
      handleEdit(id) {
        this.$router.push({
          name: "ticket-update",
          params: {
            ticketId: id
          }
        })
      },
      closeModal() {
        this.formModalActive = false;
        this.fetchTickets()
      },
      closeTicketModal(data = null) {
        if (data) {
          this.new_ticket = data
        }
        this.modalActive = false
      },
      generateExcel() {
        this.showLoading = true;
        getTicketExcel(this.report).then(({data}) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', this.report.name ? this.report.name+'.xlsx' : 'ticket-excel.xlsx');
          document.body.appendChild(link);
          link.click()
          this.showLoading = false;
          this.reportModal=false
        })
      },
      handleDelete(id) {
        if (id) {
          Swal.fire({
            title: this.$t('areYouSure'),
            text: this.$t('wontReturn'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('YesDelete'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              // eslint-disable-next-line no-unused-vars
              deleteTickets({
                id
              }).then(res => {
                Swal.fire({
                  icon: 'success',
                  position: 'center',
                  title: this.$t('Deleted'),
                  text: this.$t('dataHasDeleted'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.fetchTickets()
              })
            } else if (result.dismiss === 'cancel') {
              Swal.fire({
                title: this.$t('Cancelled'),
                text: this.$t('yrDataSafe'),
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      },
      openTicketModal() {
        this.modalActive = true
      },
      goToRouter() {
        this.$router.push({
          name: 'ticket-add'
        })
      },
      getBranchesOptions() {
        getBranches().then( res => {
          this.branchesOptions = res.data.data;
        })
      },
      getDataByFilter() {
        this.newFilter.branch_id = this.filter.branch_id ? this.filter.branch_id.id : null;
        this.newFilter.date = this.filter.date ? moment(this.filter.date).format('YYYY-MM-DD') : null;
        this.fetchTickets();
      },
      handleShow(id) {
        this.$router.push({name: 'ticket-details', params: {ticketDetailsId: id}})
      }
    },
    created() {
      this.fetchTickets();
      this.getBranchesOptions();
    },
  }
</script>

<template>
  <div>
    <b-overlay :show="loading">
      <validation-observer ref="refFormObserver">
        <validation-provider #default="validationContext" :name="$t('message.Name')" rules="required">
          <b-form-group :label="$t('message.Name')" for="name">
            <b-form-input autofocus v-model="hotels.name"></b-form-input>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>

        <validation-provider #default="validationContext" :name="$t('message.Stars_count')" rules="required">
          <b-form-group :label="$t('message.Stars_count')" for="stars-count">
            <b-form-rating v-model="hotels.stars_count"></b-form-rating>
            <span class="text-danger">
              {{ validationContext.errors[0] }}
            </span>
          </b-form-group>
        </validation-provider>


        <!--      <label class="mt-2">{{$t('message.Regions')}}</label>-->
        <!--      <b-form-select-->
        <!--          v-model="hotels.country_id"-->
        <!--          :options="countries"-->
        <!--          text-field="name_uz"-->
        <!--          value-field="id"-->
        <!--          style="height: 40px"-->
        <!--          class="w-100"-->
        <!--      />-->
        <!--      <v-select :options="countries" label="name_uz" v-model="selectedCountry" @search="getSearchCountry"></v-select>-->

        <!-- <span class="text-danger mt-2" v-if="selectedCountry === null">
              {{$t('message.errrCountries')}}
            </span> -->

        <div class="w-100 d-flex justify-content-end mt-3">
          <b-button variant="warning" @click="$emit('closeModal')">
            {{$t('message.Cancel')}}
          </b-button>
          <b-button variant="primary" class="ml-1" @click="handleSubmit" :disabled="disabled">
            {{$t('message.Save')}}
          </b-button>
        </div>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
  import {
    ValidationProvider,
    ValidationObserver
  } from 'vee-validate'
  import {
    required,
    alphaNum
  } from '@validations'
  import {
    addHotels,
    editHotels,
    getHotelsById
  } from '@/api/hotels'
  import {
    getCountries,
    getCountriesById
  } from '@/api/countries/countries'
  import vSelect from 'vue-select';

  export default {
    name: 'HotelsForm',
    components: {
      ValidationProvider,
      ValidationObserver,
      vSelect,
    },
    props: {
      id: {
        default: null
      }
    },
    data() {
      return {
        required,
        alphaNum,
        hotels: {
          id: null,
          name: '',
          stars_count: '',
          country_id: ''
        },
        selectedCountry: null,
        countries: [],
        disabled: false,
        loading: false,
      }
    },
    methods: {
      handleSubmit() {
        const isValid = this.$refs.refFormObserver.validate();
        if (isValid) {
          this.disabled = true;
          this.loading = true;
          setTimeout(() => {
            this.disabled = false;
            this.loading = false;
          }, 1000)
          // this.hotels.country_id = this.selectedCountry.id;
          if (this.id) {
            this.hotels.id = this.id;
            // eslint-disable-next-line no-unused-vars
            editHotels(this.hotels).then(res => {
              this.$emit('closeModal')
            })
          } else {
            // eslint-disable-next-line no-unused-vars
            addHotels(this.hotels).then(res => {
              this.$emit('closeModal', res.data.data)
            })
          }
        }
      },
      fetchHotelById() {
        getHotelsById(this.id).then(res => {
          this.hotels = res.data.data;
        })
        getCountriesById().then(res => {
          this.countries = res.data.data
        })
      },
      getSearchCountry(search) {
        if (search) {
          this.timesOut = setTimeout(() => {
            getCountries({
                search
              })
              .then(res => {
                this.countries = res.data.data.data
              })
          }, 600)
        }
      },
    },
    watch: {
      id: {
        immediate: true,
        handler(val) {
          if (val) {
            this.fetchHotelById()
          }
        }
      }
    }
  }
</script>
import request from '../../libs/axios'

export function getHotels(params){
  return request({
    url: '/api/hotels',
    method: 'get',
    params,
  })
}

export function addHotels(data){
  return request({
    url: '/api/hotels',
    method: 'post',
    data,
  })
}

export function editHotels(data){
  return request({
    url: `/api/hotels/${data.id}`,
    method: 'put',
    data,
  })
}

export function deleteHotels(data){
  return request({
    url: `/api/hotels/${data.id}`,
    method: 'delete'
  })
}

export function deleteHotelSale(data) {
  return request({
    url: `/api/hotel-sales/${data.id}`,
    method: 'delete',
  })
}

export function getHotelsById(id) {
  return request({
    url: `/api/hotels/${id}`,
    method:'get',
  })
}

export function storeClientHotel(data) {
  return request({
    url: '/api/store-client-hotel',
    method: 'post',
    data,
  })
}


export function updateClientHotel(data) {
  return request({
    url: `/api/store-client-hotel/${data.id}`,
    method: 'put',
    data,
  })
}

export function showClientHotel(id) {
  return request({
    url: `/api/hotel-sales/${id}`,
    method: 'get',
  })
}

export function getClientHotels(params) {
  return request({
    url: '/api/get-client-hotels',
    method: 'get',
    params,
  })
}

import request from '../../libs/axios'

export function getPartners(params) {
  return request({
    url: '/api/partners',
    method: 'get',
    params
  })
}

export function addPartners(data){
  return request({
    url: '/api/partners',
    method: 'post',
    data,
  })
}

export function editPartners(data){
  return request({
    url: `/api/partners/${data.id}`,
    method: 'put',
    data,
  })
}

export function deletePartners(data){
  return request({
    url: `/api/partners/${data.id}`,
    method: 'delete'
  })
}

export function getPartnersById(id) {
  return request({
    url: `/api/partners/${id}`,
    method:'get',
  })
}

import request from '../../libs/axios'


export function getAviaticketReports(params) {
    return request({
        url: '/api/get-avia-ticket-reports',
        method: 'get',
        params,
    })
}

export function getTicketExcel(params) {
    return request({
        url:'/api/ticket-excel-reports',
        method:'get',
        params,
        responseType: 'blob'
    })
}
